<template>
  <div class="search search-block">
    <v-icon class="mr-2" color="var(--white)" @click="focusSearch">
      search
    </v-icon>
    <input
      type="text"
      v-no-cyrillic
      ref="search"
      @input="debounceFilterGames"
      @keydown.enter="filterGames"
      v-model="filters.name"
      :placeholder="$t('search')"
      class="search__input"
    />
    <div v-if="filters.name" @click="clearFilter" class="search__reset-button">
      <v-icon color="var(--white)"> clear </v-icon>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";
export default {
  name: "TheSearch",
  data() {
    return {
      filters: {
        page: 1,
        size: 50,
        name: null,
        provider: null,
      },
      holdAllGamesName: "",
      holdFavoriteGamesName: "",
    };
  },
  computed: {
    ...mapGetters({
      providers: "gameModule/getProviders",
      currentTab: "gameModule/getCurrentTab",
    }),
  },
  watch: {
    currentTab(newV) {
      if (newV) {
        // this.filterGames();
        this.filters.name = null;
        if (newV === "favorite") {
          this.filters.name = this.holdFavoriteGamesName;
        } else if (newV === "all") {
          this.filters.name = this.holdAllGamesName;
        }
      }
    },
    // 'filters.name': {
    //     handler(newV) {
    //         console.log('newV', newV);
    //     },
    //     immediate: true
    // }
  },
  methods: {
    focusSearch() {
      this.$refs.search.focus();
    },
    debounceFilterGames: debounce(function () {
      this.filterGames();
    }, 550),
    clearFilter() {
      this.filters.name = null;
      if (this.currentTab === "all") {
        this.filters.provider = this.providers.items[0].string_id;
      }
      this.filterGames();
      this.filters.provider = null;
    },
    async filterGames() {
      if (!this.filters.name) {
        return;
      }
      let endpoint;
      if (this.filters.name) {
        this.filters.name = this.filters.name.trim();
      }
      if (this.currentTab === "favorite") {
        // this.filters.is_favourite = true;
        endpoint = "favoriteGames/awaitGetFavoriteGames";
        this.holdFavoriteGamesName = this.filters.name;
      } else {
        this.filters.is_favourite = null;
        // this.$delete(this.filters, 'is_favourite')
        endpoint = "gameModule/awaitGetGames";
        this.holdAllGamesName = this.filters.name;
      }

      this.$store.commit("gameModule/set", {
        type: "loadingProviders",
        items: true,
      });
      await this.$store.dispatch(endpoint, this.filters);
      this.$store.commit("gameModule/set", {
        type: "loadingProviders",
        items: false,
      });
      this.$store.commit("gameModule/set", {
        type: "providersSearchTrigger",
        items: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  flex: 1;
  height: 52px;
  position: relative;
  display: flex;
  padding: 0 8px 0 32px;
  justify-content: center;
  align-items: center;
  &__input {
    width: 120px;
    height: 60px;
    min-width: 100px;
    padding-right: 20px;
    border: none;
    font-size: 12px;
    //text-transform: uppercase;
    background-color: transparent;
    transition: width 0.25s ease, min-width 0.25s ease, padding-right 0.25s ease;
    position: relative;
    outline: none;
    color: var(--white);

    &::placeholder {
      color: var(--white);
    }
  }

  &__reset-button {
    top: 13px;
    right: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    position: absolute;
    cursor: pointer;
  }
}
.search-block {
}
</style>
