<template>
  <div :class="{ 'preloader-overlay': isOverlay }">
    <!-- <img src="../assets/images/cards-spades.svg" width="25px" height="25px" class="preloader__img preloader__img_f">
      <img src="../assets/images/cards-clubs.svg" width="25px" height="25px" class="preloader__img preloader__img_s">
      <img src="../assets/images/cards-diamonds.svg" width="25px" height="25px" class="preloader__img preloader__img_four">
      <img src="../assets/images/white-heart.svg" width="25px" height="25px" class="preloader__img preloader__img_t">  -->

    <!-- <img src="../assets/images/pre_loader.png" width="100px" height="100px" class="preloader_img"> -->
    <div class="logo-preloader">
      <img :src="`${storageUrl}/ui-style/${logo}`" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { storageUrl } from "@/utils/variables";
export default {
  name: "Preloader",
  props: {
    isOverlay: {
      required: false,
      type: Boolean,
      default: false,
    },
    disableScroll: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      storageUrl: storageUrl,
    };
  },
  computed: {
    ...mapGetters({
      logo: "styles/getLogo",
    }),
  },
  mounted() {
    if (this.disableScroll) {
      document.body.style.position = "fixed";
    }
  },
  destroyed() {
    if (this.disableScroll) {
      document.body.style.removeProperty("position");
    }
  },
};
</script>

<style scoped lang="scss">
.preloader-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(141, 36, 170, 0.308);
  backdrop-filter: blur(3px);
  z-index: 3;
}
.logo-preloader {
  z-index: 2;
  position: relative;
  display: block;
  animation: rippleLogo 1.5s infinite linear;
  width: 150px;
  height: 150px;
  img {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    z-index: 5;
  }
}
$color: #fc5185;

@keyframes rippleLogo {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
</style>
