<template>
  <div>
    <div class="page">
      <!-- :class="{ '_top' : isOpenMenu }"><jackpot-item /></div> -->
      <!-- class="jackpot-game" -->
      <div class="jackpot-wrapper">
        <jackpot-item />
        <jackpot-item rtl class="mobile-hide" />
      </div>
      <mobile-header
        class="pc-hide"
        v-if="!errorFlag && !errorBonusFlag"
        @fullscreen="toFullScreen"
        :IsFullScreen="isFullScreen"
      />
      <div class="session">
        <v-skeleton-loader
          width="100%"
          height="100%"
          type="image"
          class="skeleton-full"
          v-if="loadingFlag"
        ></v-skeleton-loader>
        <iframe
          v-if="!errorFlag && !errorBonusFlag && !loadingFlag"
          :src="frameUrl"
          id="frame_id_mobile"
          class="session-frame"
        ></iframe>
      </div>
      <error-game :isNoMoney="isNoMoney" v-if="errorFlag" />
      <bonus-error-game v-if="errorBonusFlag" />
      <div class="mobile-hide">
        <desktop-mini-footer
          :IsGame="true"
          :IsFullScreen="isFullScreen"
          @close-game="closeGame"
          @fullscreen="toFullScreen"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MobileHeader from "@/components/MobileHeader";
import DesktopMiniFooter from "@/components/DesktopMiniFooter";
import JackpotItem from "@/components/Jackpot/JackpotItem.vue";
import ErrorGame from "./ErrorGame";
import BonusErrorGame from "./BonusErrorGame";
import { mapGetters } from "vuex";

export default {
  name: "Game",
  //components: {DepositBonusCards},
  components: {
    MobileHeader,
    DesktopMiniFooter,
    JackpotItem,
    ErrorGame,
    BonusErrorGame,
  },
  data() {
    return {
      isIOS: /iPhone|iPad|iPod/i.test(navigator.userAgent),
      loadingCount: 0,
      loadingFlag: false,
      menuDrawer: false,
      fullScreenFlag: false,
      dynamicIframeHeight: "100vh",
      gameBarItems: [
        {
          icon: "list",
          clickFunction: () => {
            this.menuDrawer = !this.menuDrawer;
          },
        },
        {
          icon: "fullscreen",
          clickFunction: this.toFullScreen,
        },
      ],
      currentIndex: 0,
      animationUpdate: null,
      frameUrl: null,
      gameUrls: {},
      gameDialog: true,
      errorBonusFlag: false,
      errorFlag: false,
      isFullScreen: false,
      isNoMoney: false,
    };
  },
  computed: {
    isOpenMenu() {
      return this.$store.state.openMenu;
    },
    windowWidth() {
      return window.innerWidth;
    },
    ...mapGetters({
      logo: "styles/getLogo",
      user: "getUserInfo",
      jackpotWin: "getJackpotWin",
    }),
  },
  async destroyed() {
    window.removeEventListener("resize", this.resizeFrame);
    clearInterval(this.animationUpdate);
    // chat.showChat();
    if (this.user.id) {
      await this.$store.dispatch("awaitGetUserInfo");
      await this.$store.dispatch("awaitGetJackpot");
      this.$store.commit("gameModule/set", {
        type: "providersSearchTrigger",
        items: true,
      });
    }
  },
  async mounted() {
    const gameID = this.getGameByUrl();
    if (gameID) {
      await this.getGameUrl(gameID);
    } else {
      this.errorFlag = true;
    }

    window.addEventListener("resize", this.resizeFrame);
    this.iframeRef = this.$refs.iframeRef;
  },
  watch: {
    jackpotWin: {
      handler(newV) {
        if (newV) {
          this.cancelFullScreen();
        }
      },
      immediate: true,
      deep: true,
    },
    "$store.state.socket2"(newValue) {
      console.log("newValue", newValue);
      if (newValue) {
        console.log("newValue", newValue);
        this.cancelFullScreen();
        this.$router.push("/maintenance");
      }
    },
  },
  methods: {
    getGameByUrl() {
      const url = window.location.pathname;
      const parts = url.split("/");
      // const gameID = Number(parts[2]);
      const gameID = parts[parts.length - 1];
      // console.log('gameID', gameID, 'demoFlag', demoFlag);
      return gameID;
    },

    deviceType() {
      const width = this.windowWidth;
      if (width >= 1024) {
        return "1";
      } else if (width >= 481 && width < 1024) {
        return "3";
      } else {
        return "2";
      }
    },
    async getGameUrl(id) {
      this.isNoMoney = false;
      const [url, err] = await this.$store.dispatch("getGameUrl", {
        id: id,
        locale: this.$i18n.locale,
        demo_flag: false,
        client_type: this.deviceType(),
      });
      if (!url) {
        if (err === "BonusLiveError") {
          this.errorBonusFlag = true;
        } else {
          if (err === "isNoMoney") {
            this.isNoMoney = true;
          }
          this.errorFlag = true;
        }
      } else {
        this.gameUrls = url;
        this.frameUrl = this.gameUrls.real;
      }
    },
    closeGame() {
      this.$router.push("/");
      if (this.isFullScreen) {
        this.cancelFullScreen();
      }
    },
    cancelFullScreen() {
      if (this.errorBonusFlag || this.errorFlag) {
        return;
      } else if (!this.isFullScreen) {
        return;
      }

      this.isFullScreen = false;
      var doc = window.document;
      var cancelFullScreen =
        doc.exitFullscreen ||
        doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen;
      cancelFullScreen.call(doc);
    },
    toFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      var doc = window.document;
      var docEl = doc.documentElement;

      var requestFullScreen =
        docEl.requestFullscreen ||
        docEl.mozRequestFullScreen ||
        docEl.webkitRequestFullScreen;
      var cancelFullScreen =
        doc.exitFullscreen ||
        doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen;

      if (
        !doc.fullscreenElement &&
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement
      ) {
        requestFullScreen.call(docEl);
      } else {
        cancelFullScreen.call(doc);
      }
    },
    resizeFrame() {
      //console.log('resize triggered',);
      let frame = document.getElementById("frame_id");
      if (frame !== null) {
        document.getElementById("frame_id").dispatchEvent(new Event("resize"));
      }
    },
    async moveHome() {
      await this.$router.push("/");
    },
    async frameLoad(event, context) {
      const iframe = event.target;
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) {
        iframe.msRequestFullscreen();
      }
      iframe.style.width = "100%";
      iframe.style.height = "100vh";
      if (this.loadingCount === 2) {
        await this.moveHome();
      } else {
        this.loadingCount += 1;
      }
    },
    // toFullScreen() {
    //   let frame = document.getElementById('frame_id_mobile');
    //   const iframeElement = this.iframeRef;
    //   const doc = document.documentElement;
    //   if (iframeElement) {
    //     if (iframeElement.requestFullscreen) {
    //       iframeElement.requestFullscreen();
    //     } else if (iframeElement.mozRequestFullScreen) {
    //       iframeElement.mozRequestFullScreen();
    //     } else if (iframeElement.webkitRequestFullscreen) {
    //       doc.webkitRequestFullscreen();
    //       iframeElement.webkitRequestFullscreen();
    //     } else if (iframeElement.msRequestFullscreen) {
    //       iframeElement.msRequestFullscreen();
    //     } else if (iframeElement.webkitEnterFullscreen) {

    //       doc.webkitRequestFullscreen();
    //       iframeElement.webkitEnterFullscreen();
    //     }
    //     //if (!this.fullScreenFlag) {
    //     //  frame.style.position = 'fixed';
    //     //  frame.style.left = '0';
    //     //  frame.style.top = '0';
    //     //  frame.style.height = '100%';
    //     //  frame.style.zIndex = '180';
    //     //} else {
    //     //  frame.style.position = '';
    //     //}
    //     //this.fullScreenFlag = !this.fullScreenFlag;
    //   }
    // },
    // exitFullscreen() {
    //   if (document.exitFullscreen) {
    //     document.exitFullscreen();
    //   } else if (document.mozCancelFullScreen) {
    //     document.mozCancelFullScreen();
    //   } else if (document.webkitExitFullscreen) {
    //     document.webkitExitFullscreen();
    //   } else if (document.msExitFullscreen) {
    //     document.msExitFullscreen();
    //   }
    // }
  },
};
</script>

<style lang="scss" scoped>
.jackpot-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.jackpot-game {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  z-index: 11;
  transition: all 0.3s;
}
._top {
  top: 0px;
}
.mobile-hide {
  @media (max-width: 1024px) {
    display: none;
  }
}
.pc-hide {
  @media (min-width: 1024px) {
    display: none;
  }
}
.page {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.session {
  //width: 100%;
  flex: 1 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  position: relative;
  overflow: hidden;
}
.session-frame {
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
</style>
