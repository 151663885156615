<template>
  <div class="error-block">
    <div class="closeIcon" @click="moveHome">
      <v-icon color="var(--white)">close</v-icon>
    </div>
    <img width="150px" :src="`${storageUrl}/ui-style/${logo}`" alt="logo" />
    <div v-if="isNoMoney">
      {{ $i18n.t("noMoney") }}
    </div>
    <div v-else>
      {{ $i18n.t("slotKnow") }}
      <br />
      {{ $i18n.t("interrogation") }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { storageUrl } from "@/utils/variables";
export default {
  name: "ErrorGame",
  data() {
    return {
      storageUrl: storageUrl,
    };
  },
  props: {
    isNoMoney: Boolean,
  },
  methods: {
    async moveHome() {
      await this.$router.push("/");
    },
  },
  computed: {
    ...mapGetters({
      logo: "styles/getLogo",
    }),
  },
};
</script>

<style lang="scss" scoped>
.error-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: var(--white);
  font-size: 25px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.closeIcon {
  background-color: var(--red);
  border-radius: 4px;
  flex: 0 0 35px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40px;
  left: 20px;
  z-index: 100;
  @media (any-hover: hover) {
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    top: 50px;
  }
}
</style>
