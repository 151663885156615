<template>
  <div class="home-jackpots">
    <div class="logo">
      <img :src="`${storageUrl}/ui-style/${logo}`" alt="logo" />
    </div>
    <div class="jackpots">
      <div class="jackpots__container">
        <jackpot-item />
        <jackpot-item :rtl="true" />
      </div>
    </div>
    <div class="sticker">
      <v-icon color="var(--white)">mdi-arrow-up</v-icon>
      <div class="sticker__title">{{ $t("swipeUp") }}</div>
    </div>
  </div>
</template>

<script>
import JackpotItem from "@/components/Jackpot/JackpotItem.vue";
import { storageUrl } from "@/utils/variables";
import { mapGetters } from "vuex";
export default {
  components: {
    JackpotItem,
  },
  name: "HomeJackpots",
  computed: {
    ...mapGetters({
      logo: "styles/getLogo",
    }),
  },
  data() {
    return {
      storageUrl: storageUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.sticker {
  left: 50%;
  bottom: 108px;
  color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  animation: sticker_move 1.5s infinite both;
  transform: translateX(-50%);
  position: absolute;
  user-select: none;
  opacity: 0.8;
  @media (orientation: landscape) and (max-width: 1023px) {
    bottom: 23px;
  }
  @media (orientation: portrait) and (max-height: 620px) {
    display: none;
    animation: none;
  }
  @media (orientation: portrait) and (max-height: 700px) {
    bottom: 88px;
  }
  i {
    width: 26px;
    height: 26px;
  }
  &__title {
    font-size: 18px;
    text-transform: uppercase;
    white-space: nowrap;
    animation: sticker_title_move 1.5s infinite both;
    @keyframes sticker_title_move {
      0% {
        transform: translate3d(0, 0px, 0);
      }
      50% {
        transform: translate3d(0, 5px, 0);
      }
      100% {
        transform: translate3d(0, 0px, 0);
      }
    }
  }
  @keyframes sticker_move {
    0% {
      transform: translateX(-50%) translateZ(0);
    }
    50% {
      transform: translateX(-50%) translate3d(0, -12px, 0);
    }
    100% {
      transform: translateX(-50%) translateZ(0);
    }
  }
}

.home-jackpots {
  position: relative;
  @media (min-width: 1024px) {
    height: 87px;
    margin-top: 15px;
  }
  @media (max-width: 1024px) {
    height: 100vh;
  }
}
.logo {
  top: 50%;
  left: 50%;
  width: 23%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1000;
  @media (min-width: 1024px) {
    height: 87px;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  @media (max-width: 1024px) {
    display: none;
  }
}
.jackpots {
  height: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__container {
    display: flex;
    @media (min-width: 1025px) {
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
      position: relative;
      padding: 12px 0;
      margin: 0 5px;
    }
    @media (min-width: 1366px) {
      margin: 0 25px;
    }
    .jackpot {
      @media (max-width: 1024px) {
        &:last-child {
          display: none;
        }
      }
    }
  }
}
</style>
